import React from 'react';
import AlgoliaFacetRefinement from './AlgoliaFacetRefinement';
import AlgoliaFacetRange from './AlgoliaFacetRange';
import AlgoliaFacetToggle from './AlgoliaFacetToggle';
import AlgoliaFacetNumericMenu from './AlgoliaFacetNumericMenu';

interface AlgoliaFacetProps {
  facetName: string;
}

const AlgoliaFacet = ({ facetName }: AlgoliaFacetProps) => {
  return (
    <div className="mb-1">
      {facetName === 'isOutlet' && <AlgoliaFacetToggle facetName="isOutlet" />}
      {facetName === 'discountPercentage' && (
        <AlgoliaFacetNumericMenu facetName="discountPercentage" />
      )}
      {facetName === 'brand' && <AlgoliaFacetRefinement facetName="brand" />}
      {facetName === 'color' && <AlgoliaFacetRefinement facetName="color" />}
      {facetName === 'size' && <AlgoliaFacetRefinement facetName="size" />}
      {facetName === 'finalPrice' && (
        <AlgoliaFacetRange facetName="finalPrice" />
      )}
    </div>
  );
};

export default AlgoliaFacet;
