import React, { useState, useRef } from 'react';
import { useSortBy } from 'react-instantsearch';
import { useOnClickOutside } from 'usehooks-ts';
import config from '../../services/Config';
import { Language } from '../../stores/Translations';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/20/solid';

const SorterOptions = ({
  options,
  locale,
}: {
  options: { value: string; label: string }[];
  locale: Language;
}) => {
  const algoliaIndexName =
    locale === Language.RU
      ? config.algoliaRuSearchIndex
      : config.algoliaSearchIndex;

  const mappedOptions = options.map((option) => {
    let value = algoliaIndexName;
    if (option.value === 'PRICE_ASC') {
      value = `${algoliaIndexName}_price_asc`;
    }
    if (option.value === 'PRICE_DESC') {
      value = `${algoliaIndexName}_price_desc`;
    }
    if (option.value === 'NEW_ARRIVAL_DESC') {
      value = `${algoliaIndexName}_date_desc`;
    }

    return {
      label: option.label,
      value,
    };
  });

  const ref = useRef(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const { refine, currentRefinement } = useSortBy({
    items: mappedOptions,
  });

  const selectedOption = mappedOptions.find(
    (option) => option.value === currentRefinement,
  );

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  useOnClickOutside(ref, () => {
    setIsDropdownOpen(false);
  });

  return (
    <div
      className="flex flex-wrap font-axi-book text-s w-full mt-2 mb-3 lg:ml-2 lg:w-60 lg:mt-3 lg:mb-4 relative"
      ref={ref}
    >
      <button
        className="flex px-3 py-2 justify-between border border-rgrey-light rounded cursor-pointer items-center w-full"
        onClick={toggleDropdown}
        aria-expanded={isDropdownOpen}
        aria-haspopup="listbox"
      >
        {selectedOption?.label}
        {isDropdownOpen ? (
          <ChevronUpIcon className="lg:w-5 lg:h-5 w-6 h-6" />
        ) : (
          <ChevronDownIcon className="lg:w-5 lg:h-5 w-6 h-6" />
        )}
      </button>
      {isDropdownOpen && (
        <div
          className="rounded lg:absolute lg:mt-10 mt-2 bg-white border border-rgrey-light text-center w-full lg:w-fit"
          role="listbox"
        >
          {mappedOptions.map((option) => (
            <button
              key={option.value}
              className="px-3 py-2 cursor-pointer border-rgrey-light hover:bg-rgrey-light rounded w-full text-start"
              onClick={() => {
                refine(option.value);
                setIsDropdownOpen(false);
              }}
              role="option"
              aria-selected={option.value === currentRefinement}
            >
              {option.label}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default SorterOptions;
