import React from 'react';
import AlgoliaFacet from './AlgoliaFacet';

interface AlgoliaFacetsProps {
  facetsToRender: string[];
}

const AlgoliaFacets = ({ facetsToRender }: AlgoliaFacetsProps) => {
  return (
    <div className="flex w-full flex-col lg:flex-row">
      <div className="lg:flex lg:flex-wrap">
        {facetsToRender.map((facetName) => (
          <AlgoliaFacet key={facetName} facetName={facetName} />
        ))}
      </div>
    </div>
  );
};

export default AlgoliaFacets;
