import React, { useRef } from 'react';
import { useRefinementList } from 'react-instantsearch';
import Translate from '../Translate/Translate';

interface AlgoliaFacetToggleProps {
  facetName: string;
}

const AlgoliaFacetToggle = ({ facetName }: AlgoliaFacetToggleProps) => {
  const checkboxRef = useRef(null);
  const handleButtonClick = () => {
    if (checkboxRef.current) {
      checkboxRef.current.checked = !checkboxRef.current.checked;
    }
    refine('true');
  };
  const { items, refine } = useRefinementList({
    attribute: facetName,
  });
  const isRefined = items.some((v) => v.isRefined);
  return (
    <div
      className={`relative lg:mr-3 border-b lg:border-none cursor-pointer
       ${isRefined && 'pb-3 lg:pb-0'}`}
    >
      <button
        className={`flex py-1.5 justify-between border-rgrey-light lg:w-fit lg:border lg:rounded lg:px-3 w-full ${
          isRefined && 'px-2 lg:px-0'
        } ${
          isRefined
            ? 'bg-ryellow border-ryellow rounded '
            : 'border-rgrey-light bg-white'
        }`}
        onClick={handleButtonClick}
      >
        <p
          className={`lg:text-s lg:pr-2 lg:p-1 lg:capitalize lg:mb-0 h4 uppercase -mb-1 ${
            isRefined ? 'font-axi-bold' : 'lg:font-axi-book'
          }`}
        >
          <Translate component="facet-toggle" keyword="discount" />
        </p>
        <label
          htmlFor="AcceptConditions"
          className="relative h-6 w-10 cursor-pointer"
        >
          <input
            type="checkbox"
            id="AcceptConditions"
            className="peer sr-only "
            ref={checkboxRef}
            checked={isRefined}
            onClick={handleButtonClick}
          />
          <span
            className={`absolute inset-y-0 start-0 z-10 text-xs inline-flex h-6 w-6 items-center justify-center rounded-full bg-ryellow text-black transition-all peer-checked:start-5 peer-checked:text-white peer-checked:bg-black `}
          >
            %
          </span>
          <span className="absolute inset-0 border border-rgrey-light rounded-full bg-white transition"></span>
        </label>
      </button>
    </div>
  );
};

export default AlgoliaFacetToggle;
