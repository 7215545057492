import React, { useContext } from 'react';
import AlgoliaContext from '../../context/AlgoliaContext';
import OrderedCategory from './OrderedCategory';

const Category = () => {
  const algoliaContext = useContext(AlgoliaContext);
  return <OrderedCategory locale={algoliaContext.appLocale} />;
};

export default Category;
