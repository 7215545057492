import React, { useState, useEffect } from 'react';
import {
  useClearRefinements,
  useCurrentRefinements,
} from 'react-instantsearch';
import { ArrowPathIcon, XMarkIcon } from '@heroicons/react/20/solid';
import { translate } from '../../services/Translations';
import Translate from '../Translate/Translate';

const AlgoliaCurrent = () => {
  const [translatedOutletLabel, setTranslatedOutletLabel] = useState('');
  const { items } = useCurrentRefinements({
    excludedAttributes: [
      'categoriesSlug.lvl0',
      'categoriesSlug.lvl1',
      'categoriesSlug.lvl2',
      'categoriesSlug.lvl3',
    ],
  });

  const clearRefinements = useClearRefinements({
    excludedAttributes: [
      'categories.lvl0',
      'categories.lvl1',
      'categories.lvl2',
      'categories.lvl3',
      'categoriesSlug.lvl0',
      'categoriesSlug.lvl1',
      'categoriesSlug.lvl2',
      'categoriesSlug.lvl3',
    ],
  });

  const hasCurrentItems = !!items?.length;

  useEffect(() => {
    const translateLabel = async () => {
      const translated = await translate('facet-toggle', 'discount');
      setTranslatedOutletLabel(translated);
    };

    translateLabel();
  }, []);

  if (!hasCurrentItems) {
    return null;
  }

  return (
    <div className="flex items-center pb-3 lg:py-4 lg:border-t lg:border-rgrey-light">
      <div className="hidden lg:flex mr-4 min-w-max text-s">
        <Translate component="products" keyword="picked-filter" />
      </div>
      <div className="flex flex-wrap">
        {items.map((facet) => {
          return facet.refinements.map((refinement) => (
            <button
              className={`mr-3 my-1.5 rounded py-0.5 pl-2 pr-1 flex justify-center items-center text-s font-axi-bold leading-5 text-black ${
                refinement.attribute === 'isOutlet' ||
                refinement.attribute === 'discountPercentage'
                  ? 'bg-ryellow'
                  : 'bg-rgrey-lightlight'
              }`}
              key={`${refinement.attribute}-${refinement.value}`}
              onClick={() => {
                facet.refine(refinement);
              }}
            >
              {facet.label === 'discountPercentage' && '% '}
              {facet.label === 'finalPrice' && '€ '}
              {refinement.attribute === 'isOutlet'
                ? translatedOutletLabel
                : refinement.label}
              <div className="flex rounded-full ml-1 p-0">
                <XMarkIcon
                  className={`h-4 ${
                    refinement.attribute === 'isOutlet' ||
                    refinement.attribute === 'discountPercentage'
                      ? 'fill-black'
                      : 'fill-rgrey'
                  }`}
                />
              </div>
            </button>
          ));
        })}

        <div className="flex justify-center md::min-w-fit md::items-center md::order-none">
          {clearRefinements.canRefine && (
            <button
              className="py-2 flex items-center"
              onClick={() => {
                clearRefinements.refine();
              }}
            >
              <p className="text-s font-axi-book text-black text-left md:w-32 flex items-center">
                <ArrowPathIcon className="h-4 w-4 m-1" />
                <Translate component="facets" keyword="reset-all" />
              </p>
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default AlgoliaCurrent;
