import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useSearchBox, Pagination } from 'react-instantsearch';
import { useDebounceValue } from 'usehooks-ts';
import classNames from 'classnames';
import AlgoliaFacets from './AlgoliaFacets';
import Sorter from './Sorter';
import Translate from '../Translate/Translate';
import AlgoliaFacetHierarchical from './AlgoliaFacetHierarchical';
import AlgoliaCurrent from './AlgoliaCurrent';
import AlgoliaProducts from './AlgoliaProducts';
import { translate } from '../../services/Translations';
import { Language } from '../../stores/Translations';
import { MagnifyingGlassIcon } from '@heroicons/react/20/solid';

const Products = ({ locale }: { locale: Language }) => {
  const attributesToRender = [
    'categoriesSlug',
    'discountPercentage',
    // 'isOutlet',
    'size',
    'brand',
    'color',
    'finalPrice',
  ];

  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const { refine, query } = useSearchBox();
  const [searchTerm, setSearchTerm] = useState(queryParams.get('query') || '');
  const [debouncedQuery, setDebouncedQuery] = useDebounceValue('', 300);
  const [isOpenCategory, setIsOpenCategory] = useState(false);
  const [isOpenFilter, setIsOpenFilter] = useState(false);
  const [translatedPlaceholder, setTranslatedPlaceholder] = useState('');

  useEffect(() => {
    setDebouncedQuery(searchTerm);
  }, [searchTerm]);

  useEffect(() => {
    if (debouncedQuery !== query) {
      refine(debouncedQuery);
    }
  }, [debouncedQuery]);

  const handleCategory = () => {
    setIsOpenCategory(!isOpenCategory);
    setIsOpenFilter(false);
    if (!isOpenCategory) window.scrollTo(0, 0);
  };
  const handleFilter = () => {
    setIsOpenFilter(!isOpenFilter);
    setIsOpenCategory(false);
    if (!isOpenFilter) window.scrollTo(0, 0);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    const translatePlaceholder = async () => {
      const translated = await translate('search', 'search');
      setTranslatedPlaceholder(translated);
    };

    translatePlaceholder();
  }, []);

  return (
    <div className="w-full relative">
      <div className="w-full flex justify-between bg-white mb-3 pb-2 h-fit lg:hidden">
        <div className="absolute bg-white -top-6 w-full h-6 z-10"></div>
        <button
          onClick={handleCategory}
          className={classNames(
            'py-2 border rounded border-rgrey-light mr-2 w-6/12 px-6 h4 xs:px-3 xs:text-lg xs:font-axi-bold',
            {
              'bg-black text-white': isOpenCategory,
            },
          )}
        >
          <Translate component="products" keyword="category-button" />
        </button>
        <button
          onClick={handleFilter}
          className={classNames(
            'py-2 border rounded border-rgrey-light w-6/12 px-6 h4 xs:px-3 xs:text-lg xs:font-axi-bold',
            {
              'bg-black text-white': isOpenFilter,
            },
          )}
        >
          <Translate component="products" keyword="filter-button" />
        </button>
      </div>

      <div className="lg:flex w-full lg:flex-row">
        {/* vasak pool */}
        <div
          className={classNames(
            'lg:top-0 lg-left-0 lg:right-0 lg:sticky lg:h-5/6',
            {
              'hidden lg:block': !isOpenCategory,
            },
          )}
        >
          <div className="w-full mb-6 lg:min-w-[350px] lg:max-w-max lg:pr-2 lg:h-[78vh] lg:overflow-y-auto lg:pb-4 lg:mr-1">
            <div className="mt-4">
              <div className="relative h-max flex items-center">
                <input
                  type="text"
                  className="rounded h-max w-full py-2.5 bg-rgrey-light !text-sm border-0 focus:outline-none focus:ring-0"
                  value={searchTerm}
                  placeholder={translatedPlaceholder}
                  onChange={(e) => {
                    setSearchTerm(e.target.value);
                  }}
                />

                <MagnifyingGlassIcon className="w-6 h-6 absolute right-4 top-2 z-10" />
              </div>
              {attributesToRender.includes('categoriesSlug') && (
                <div>
                  <AlgoliaFacetHierarchical facetName="categoriesSlug" />
                </div>
              )}
            </div>
          </div>
        </div>

        {/* parem pool */}
        <div className="h-fit w-full lg:ml-6">
          {/* Filter */}

          <div
            className={classNames(
              'lg:relative lg:top-0 lg:h-fit lg:z-10 lg:w-full lg:bg-white',
              {
                'hidden lg:block': !isOpenFilter,
              },
            )}
          >
            <div className="relative">
              <div className="justify-between items-center z-50 lg:flex lg:!z-[100] lg:border-rgrey-light lg:py-2 lg:relative">
                <AlgoliaFacets facetsToRender={attributesToRender} />
                <Sorter locale={locale} />
              </div>
            </div>
          </div>
          <AlgoliaCurrent />

          {/* toodete list */}
          <div className="products-listing">
            <div>
              <AlgoliaProducts />
              <Pagination
                className="lg:hidden"
                padding={1}
                onClick={scrollToTop}
                classNames={{
                  root: 'flex w-full paragraph justify-center',
                  list: 'flex justify-between',
                  item: 'border m-1 rounded',
                  selectedItem: 'bg-black border-black text-white',
                  pageItem: 'flex px-1',
                  link: 'flex p-3',
                }}
              />
              <Pagination
                className="hidden lg:block"
                padding={3}
                onClick={scrollToTop}
                classNames={{
                  root: 'flex w-full paragraph justify-start',
                  list: 'flex',
                  item: 'border m-1 rounded',
                  selectedItem: 'bg-black border-black text-white',
                  pageItem: 'flex px-2',
                  link: 'flex p-2',
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Products;
