import React from 'react';
import Product from '../../models/Product';
import ProductBlock from './ProductBlock';
import { SendEventForHits } from 'instantsearch.js/es/lib/utils';
import { Hit } from 'instantsearch.js';

const AlgoliaHit = ({
  hit,
  sendEvent,
}: {
  hit: Hit;
  sendEvent: SendEventForHits;
}) => {
  return <ProductBlock product={new Product(hit)} sendEvent={sendEvent} />;
};

export default AlgoliaHit;
