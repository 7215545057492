import React, { useState, useEffect, useRef } from 'react';
import { useRange } from 'react-instantsearch';
import RangeSlider from 'react-range-slider-input';
import 'react-range-slider-input/dist/style.css';
import { useOnClickOutside } from 'usehooks-ts';
import classNames from 'classnames';
import Translate from '../Translate/Translate';
import {
  ChevronDownIcon,
  ChevronUpIcon,
  MinusIcon,
} from '@heroicons/react/20/solid';

interface AlgoliaFacetRangeProps {
  facetName: string;
}

const AlgoliaFacetRange = ({ facetName }: AlgoliaFacetRangeProps) => {
  const { start, range, refine } = useRange({ attribute: facetName });
  const [currentMin, setCurrentMin] = useState(
    start[0] < range.min ? range.min : start[0],
  );
  const [currentMax, setCurrentMax] = useState(
    start[1] > range.max ? range.max : start[1],
  );
  const [isActive, setIsActive] = useState(false);
  const filterMenuRef = useRef(null);

  useEffect(() => {
    setCurrentMin(range.min);
    setCurrentMax(range.max);
  }, [range.min, range.max]);

  const handleClickOutside = () => {
    setIsActive(false);
  };

  useOnClickOutside(filterMenuRef, handleClickOutside);

  const setCurrentValues = (newValues) => {
    const newMin = Math.min(...newValues);
    const newMax = Math.max(...newValues);
    setCurrentMin(newMin < range.min ? range.min : newMin);
    setCurrentMax(newMax > range.max ? range.max : newMax);
  };

  const refineValues = () => {
    refine([currentMin, currentMax]);
  };

  const handleChangeMin = (e) => {
    if (e.target.value === '') currentMin;
    else setCurrentMin(e.target.value);
    refineValues();
  };
  const handleChangeMax = (e) => {
    if (e.target.value === '') currentMax;
    else setCurrentMax(e.target.value);
    refineValues();
  };

  return (
    <div
      ref={filterMenuRef}
      className={classNames(
        'relative border-b lg:mr-3 lg:border-none cursor-pointer',
        {
          'pb-3 lg:pb-0': isActive,
        },
      )}
    >
      <div
        onClick={() => setIsActive(!isActive)}
        className={classNames(
          'flex py-2 justify-between border-rgrey-light cursor-pointer w-full lg:w-fit lg:border lg:rounded lg:px-3',
          {
            'border-black':
              range.min !== currentMin || range.max !== currentMax,
          },
        )}
      >
        <p
          className={classNames(
            'h4 uppercase -mb-1 lg:normal-case lg:text-s lg:pr-1 lg:pt-0.5 lg:font-axi-book',
            {
              '!font-axi-bold':
                range.min !== currentMin || range.max !== currentMax,
            },
          )}
        >
          {facetName === 'finalPrice' && (
            <Translate component="facet-range" keyword="price" />
          )}
        </p>
        {isActive ? (
          <>
            <ChevronUpIcon className="hidden lg:block lg:w-5 lg:h-5" />
            <MinusIcon className="lg:hidden w-6 h-6" />
          </>
        ) : (
          <ChevronDownIcon className="lg:w-5 lg:h-5 w-6 h-6" />
        )}
      </div>
      <div
        className={classNames(
          'lg:mt-0.5 lg:pt-6 lg:px-4 lg:pb-7 lg:bg-white lg:border lg:border-rgrey-light lg:rounded lg:w-80 lg:absolute lg:z-10',
          {
            hidden: !isActive,
          },
        )}
      >
        <div className="mb-7 mt-4 h-4">
          <RangeSlider
            className=""
            min={range.min}
            max={range.max}
            value={[currentMin, currentMax]}
            onInput={(newValues) => {
              setCurrentValues(newValues);
            }}
            onRangeDragEnd={() => refineValues()}
            onThumbDragEnd={() => refineValues()}
          />
        </div>
        <div>
          <div className="h-auto flex">
            <input
              className="w-1/2 text-center p-2 bg-rgrey-lightmid border rounded border-rgrey-light m-2 paragraph"
              type="number"
              placeholder={currentMin.toString()}
              onChange={handleChangeMin}
              value={currentMin}
            />
            <input
              className="w-1/2 text-center p-2 bg-rgrey-lightmid border rounded border-rgrey-light m-2 paragraph"
              type="number"
              placeholder={currentMax.toString()}
              onChange={handleChangeMax}
              value={currentMax}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AlgoliaFacetRange;
