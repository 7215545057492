import React from 'react';
import { useHits } from 'react-instantsearch';
import AlgoliaHit from './AlgoliaHit';
import Translate from '../Translate/Translate';
import { isProd } from '../../services/Env';

const AlgoliaProducts = () => {
  const { hits, sendEvent } = useHits();

  return (
    <div>
      {hits.length === 0 && (
        <div className="h4 font-axi-book lg:mb-16 lg:mt-12 mb-8 mt-6">
          <Translate component="algolia-products" keyword="no-results" />
          <p className="paragraph">
            <Translate component="algolia-products" keyword="change-search" />
          </p>
        </div>
      )}
      {isProd() ? (
        <div className="grid-cols-2 md:grid-cols-3 xl:grid-cols-4 grid gap-2">
          {hits.map((hit) => (
            <AlgoliaHit key={hit.objectID} hit={hit} sendEvent={sendEvent} />
          ))}
        </div>
      ) : (
        <div className="grid-cols-2 md:grid-cols-3 xl:grid-cols-3 grid gap-2">
          {hits.map((hit) => (
            <AlgoliaHit key={hit.objectID} hit={hit} sendEvent={sendEvent} />
          ))}
        </div>
      )}
    </div>
  );
};

export default AlgoliaProducts;
