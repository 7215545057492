import React from 'react';
import SorterOptions from './SorterOptions';
import { Language } from '../../stores/Translations';

const Sorter = ({ locale }: { locale: Language }) => {
  // TODO: Get labels from API. But avoid re-render because Algolia seems to not like it.
  const options =
    locale === Language.RU
      ? [
          { value: 'RELEVANCE', label: 'Популярный' },
          { value: 'NEW_ARRIVAL_DESC', label: 'Сначала новые товары' },
          { value: 'PRICE_DESC', label: 'Цена по убыванию' },
          { value: 'PRICE_ASC', label: 'Цена по возрастанию' },
        ]
      : [
          { value: 'RELEVANCE', label: 'Populaarsed' },
          { value: 'NEW_ARRIVAL_DESC', label: 'Uuemad enne' },
          { value: 'PRICE_DESC', label: 'Kallimad enne' },
          { value: 'PRICE_ASC', label: 'Odavamad enne' },
        ];

  return <SorterOptions options={options} locale={locale} />;
};

export default Sorter;
