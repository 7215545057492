import React, { useRef, useState } from 'react';
import { useClearRefinements, useNumericMenu } from 'react-instantsearch';
import {
  ChevronDownIcon,
  ChevronUpIcon,
  MinusIcon,
} from '@heroicons/react/20/solid';
import classNames from 'classnames';
import Translate from '../Translate/Translate';
import { useOnClickOutside } from 'usehooks-ts';

interface AlgoliaFacetNumericMenuProps {
  facetName: string;
}

const AlgoliaFacetNumericMenu = ({
  facetName,
}: AlgoliaFacetNumericMenuProps) => {
  const clearCurrentRefinement = useClearRefinements({
    includedAttributes: [facetName],
  });
  const { items, refine } = useNumericMenu({
    attribute: facetName,
    items: [
      { label: 'alates 10%', start: 10 },
      { label: 'alates 20%', start: 20 },
      { label: 'alates 30%', start: 30 },
      { label: 'alates 40%', start: 40 },
      { label: 'alates 50%', start: 50 },
      { label: 'alates 60%', start: 60 },
    ],
  });

  const [isActive, setIsActive] = useState(false);
  const filterMenuRef = useRef(null);

  const handleClickOutside = () => {
    setIsActive(false);
  };

  useOnClickOutside(filterMenuRef, handleClickOutside);

  const itemIsRefined = items.filter((item) => item.isRefined).length;

  return (
    <div
      ref={filterMenuRef}
      className={classNames(
        'relative border-b lg:border-none lg:mr-3 cursor-pointer',
        {
          'pb-3 lg:pb-0': isActive,
        },
      )}
    >
      <div
        onClick={() => setIsActive(!isActive)}
        className={classNames(
          'flex py-2 justify-between border-rgrey-light cursor-pointer w-full lg:w-fit lg:border lg:rounded lg:px-3',
          {
            'border-black': itemIsRefined > 0,
          },
        )}
      >
        <p
          className={classNames(
            'h4 uppercase -mb-1 lg:normal-case lg:text-s lg:pr-1 lg:pt-0.5 lg:font-axi-book',
            {
              '!font-axi-bold': itemIsRefined > 0,
            },
          )}
        >
          {facetName === 'discountPercentage' && (
            <Translate component="facet-toggle" keyword="discount" />
          )}
        </p>
        <div className="flex">
          {isActive ? (
            <>
              <ChevronUpIcon className="hidden lg:block lg:w-5 lg:h-5" />
              <MinusIcon className="lg:hidden w-6 h-6" />
            </>
          ) : (
            <ChevronDownIcon className="lg:w-5 lg:h-5 w-6 h-6" />
          )}
        </div>
      </div>
      <div
        className={classNames(
          'lg:mt-0.5 lg:pt-6 lg:px-4 lg:pb-7 lg:bg-white lg:border lg:border-rgrey-light lg:rounded lg:w-80 lg:absolute lg:z-10',
          {
            hidden: !isActive,
          },
        )}
      >
        <div className="flex px-0.5 flex-wrap gap-3 justify-start lg:justify-center">
          {items.map((item) => (
            <button
              key={item.value}
              role="relative"
              onClick={() => {
                if (item.isRefined) {
                  clearCurrentRefinement.refine();
                } else {
                  refine(item.value);
                }
              }}
            >
              <div>
                <div
                  className={classNames('border rounded px-4 py-2 max-w-20', {
                    'bg-black text-white border-black': item.isRefined,
                  })}
                >
                  {item.label}
                </div>
              </div>
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AlgoliaFacetNumericMenu;
